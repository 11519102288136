<template>
  <div style="padding: 20px">
    <div class="authortitle">历史订单</div>
    <div v-if="orderlistlength != 0">
      <order :orderlist="orderlist"></order>
      <div
        class="ygdPage"
        style="
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 20px;
        "
      >
        <el-pagination
          class="fy"
          small
          @size-change="handleSizeChange"
          :page-size="pagesize"
          :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="current_change"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <div
        style="text-align: center; color: #999999; padding: 20px; width: 100%"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>
       

<script>
import order from '../components/historyorder.vue'
import { authororderlist } from "../../../API/ZZD/order.js"
export default {
  components: {
    order
  },
  data () {

    return {
      orderlist: [],
      orderlistlength: 0,
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      childpath: '/Pending/history/detail'
    }

  },
  mounted () {
    this.historyorder()
  },
  methods: {
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.historyorder()
    },
    handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.historyorder()
    },
    historyorder () {
      var id = localStorage.getItem('ZZId');
      authororderlist(id, this.currentPage, this.pagesize, 4).then(res => {
        console.log(res)
        this.total = res.data.total
        this.orderlist = res.data.data
        this.orderlistlength = this.orderlist.length
      })
    }
  }
}

</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
</style>